/* globals google */
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Box, Card } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import MaxSizeLoading from "components/MaxSizeLoading";
import PageLayout from "components/PageLayout";
import { useAllStatisticsSubscription } from "models/Statistics";
import dynamic from "next/dynamic";
import { useEffect, useRef, useState } from "react";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

function DashboardPage() {
  const [statistics, setStatistics] = useState();
  useAllStatisticsSubscription(setStatistics);

  const map = useRef();

  useEffect(() => {
    async function initMap() {
      if (!statistics) return;
      if (typeof google !== "undefined" && !map.current) {
        const { Map } = await google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } =
          await google.maps.importLibrary("marker");

        map.current = new Map(document.getElementById("map"), {
          mapId: "map",
        });

        const serviceLocations = statistics[0].locations;

        const bounds = new google.maps.LatLngBounds();
        const markers = [];
        for (let index = 0; index < serviceLocations.length; index++) {
          const latLng = serviceLocations[index];
          const marker = new AdvancedMarkerElement({
            position: latLng,
            map: map.current,
          });
          markers.push(marker);
          bounds.extend(latLng);
        }
        map.current.fitBounds(bounds);
        new MarkerClusterer({ map: map.current, markers });
      }
    }

    initMap();
  });

  if (!statistics) {
    return <MaxSizeLoading />;
  }

  return (
    <PageLayout breadcrumbs={[{ title: "Dashboard", href: "/" }]}>
      <Grid container spacing={3} paddingY={3}>
        <Box></Box>
        <Grid xs={12}>
          <Card raised elevation={24} sx={{ padding: 0 }}>
            <Box id="map" width={"100%"} minHeight={600} />
          </Card>
        </Grid>
        <Grid xs={12}>
          <Card raised elevation={24} sx={{ padding: 3 }}>
            <Chart
              type="line"
              options={{
                xaxis: { type: "datetime" },
                title: {
                  text: "Active Injection Patients",
                  style: {
                    fontSize: "16px",
                  },
                },
              }}
              series={[
                {
                  name: "Patients",
                  data: statistics.map((statistic) => [
                    statistic.createdAt.seconds * 1000,
                    statistic.activePatients,
                  ]),
                },
              ]}
            />
          </Card>
        </Grid>
        <Grid xs={12}>
          <Card raised elevation={24} sx={{ padding: 3 }}>
            <Chart
              type="bar"
              options={{
                xaxis: {
                  categories: Object.keys(statistics[0].patientsByAgeBreakdown),
                },
                title: {
                  text: "Injection Patients by Age in Years",
                  style: {
                    fontSize: "16px",
                  },
                },
              }}
              series={[
                {
                  name: "Patients",
                  data: Object.values(statistics[0].patientsByAgeBreakdown),
                },
              ]}
            />
          </Card>
        </Grid>
        <Grid xs={12}>
          <Card raised elevation={24} sx={{ padding: 3 }}>
            <Chart
              type="line"
              options={{
                xaxis: { type: "datetime" },
                title: {
                  text: "Active Injection Prescriptions",
                  style: {
                    fontSize: "16px",
                  },
                },
              }}
              series={[
                {
                  name: "Prescriptions",
                  data: statistics.map((statistic) => [
                    statistic.createdAt.seconds * 1000,
                    statistic.activePrescriptions,
                  ]),
                },
              ]}
            />
          </Card>
        </Grid>
        <Grid xs={12}>
          <Card raised elevation={24} sx={{ padding: 3 }}>
            <Chart
              type="bar"
              options={{
                xaxis: {
                  categories: Object.keys(
                    statistics[0].prescriptionsByMedicationBreakdown
                  ),
                },
                title: {
                  text: "Injection Prescriptions by Medication",
                  style: {
                    fontSize: "16px",
                  },
                },
              }}
              series={[
                {
                  name: "Prescriptions",
                  data: Object.values(
                    statistics[0].prescriptionsByMedicationBreakdown
                  ),
                },
              ]}
            />
          </Card>
        </Grid>
        <Grid xs={12}>
          <Card raised elevation={24} sx={{ padding: 3 }}>
            <Chart
              type="bar"
              options={{
                xaxis: {
                  categories: Object.keys(
                    statistics[0].prescriptionsByMedicationAndStrengthBreakdown
                  ),
                },
                title: {
                  text: "Injection Prescriptions by Medication and Strength",
                  style: {
                    fontSize: "16px",
                  },
                },
              }}
              series={[
                {
                  name: "Prescriptions",
                  data: Object.values(
                    statistics[0].prescriptionsByMedicationAndStrengthBreakdown
                  ),
                },
              ]}
            />
          </Card>
        </Grid>
        <Grid xs={12}>
          <Card raised elevation={24} sx={{ padding: 3 }}>
            <Chart
              type="bar"
              options={{
                xaxis: {
                  categories: Object.keys(
                    statistics[0].prescriptionsByPrescriberBreakdown
                  ),
                },
                title: {
                  text: "Injection Prescriptions by Prescriber",
                  style: {
                    fontSize: "16px",
                  },
                },
              }}
              series={[
                {
                  name: "Prescriptions",
                  data: Object.values(
                    statistics[0].prescriptionsByPrescriberBreakdown
                  ),
                },
              ]}
            />
          </Card>
        </Grid>
        <Grid xs={12}>
          <Card raised elevation={24} sx={{ padding: 3 }}>
            <Chart
              type="bar"
              options={{
                xaxis: {
                  categories: Object.keys(
                    statistics[0].prescriptionsByFrequency
                  ),
                },
                title: {
                  text: "Injection Prescriptions by Frequency",
                  style: {
                    fontSize: "16px",
                  },
                },
              }}
              series={[
                {
                  name: "Prescriptions",
                  data: Object.values(statistics[0].prescriptionsByFrequency),
                },
              ]}
            />
          </Card>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default DashboardPage;
